import { isDealersShopUri, uriIncludesVertical, isMoreFromSeller } from './uriUtils';
import { TYPES } from '../constants/favoritesConstants';

export function getFollowTypeFromUri(uri: string = ''): typeof TYPES.DEALER | typeof TYPES.SEARCH {
    const isDealer = isDealersShopUri(uri) && !uriIncludesVertical(uri);
    if (isDealer || isMoreFromSeller(uri)) {
        return TYPES.DEALER;
    }
    return TYPES.SEARCH;
}

export function getFollowTypeArrayFromUri(
    uri: string = ''
): [typeof TYPES.DEALER] | [typeof TYPES.SEARCH, typeof TYPES.USER_PREFERENCE] {
    const isDealer = isDealersShopUri(uri) && !uriIncludesVertical(uri);
    if (isDealer || isMoreFromSeller(uri)) {
        return [TYPES.DEALER];
    }
    return [TYPES.SEARCH, TYPES.USER_PREFERENCE];
}
