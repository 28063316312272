import { graphql, ConnectionHandler, Environment } from 'react-relay';
import commitMutation from 'relay-commit-mutation-promise';

import {
    updateSaveSearchMutation,
    updateSaveSearchMutation$data,
    types,
} from './__generated__/updateSaveSearchMutation.graphql';

const mutation = graphql`
    mutation updateSaveSearchMutation(
        $searchTypes: [types]
        $searchPages: [String]
        $input: FavoritePageInput!
    ) {
        favoritePage(input: $input) {
            user {
                savedSearches: favorites(first: 1, types: $searchTypes, pages: $searchPages)
                    @connection(key: "updateSaveSearchMutation_savedSearches", filters: []) {
                    edges {
                        node {
                            serviceId
                        }
                    }
                }
            }
        }
    }
`;

type Props = {
    connectionKey?: string;
    favoriteId: string | null;
    favoriteRelayId?: string;
    name?: string;
    page: string;
    type: types;
    userId: string;
};

export function updateSaveSearchMutation(
    environment: Environment,
    { connectionKey, favoriteId, favoriteRelayId, name, page, type = 'SEARCH', userId }: Props
): Promise<updateSaveSearchMutation$data> {
    return commitMutation<updateSaveSearchMutation>(environment, {
        mutation,
        variables: {
            input: {
                page,
                type,
                name,
                userId,
                favoriteId,
                action: favoriteId ? 'REMOVE' : 'ADD',
            },
            searchPages: [page],
            searchTypes: [type],
        },
        updater(store) {
            /**
             * Attempt to update relay store right after the mutation, instead of relying on a
             * refetch query.
             *
             * See relay docs from a better idea of the API
             * https://relay.dev/docs/guided-tour/updating-data/graphql-mutations/#updater-functions
             */
            if (connectionKey) {
                /**
                 * First, get the favorites connection that is already in the store and it is the
                 * one we will want to update.
                 */
                const viewer = store.getRoot().getLinkedRecord('viewer');
                const user = viewer && viewer.getLinkedRecord('user', { userId });
                if (user) {
                    const favoritesConnection = ConnectionHandler.getConnection(
                        user,
                        connectionKey
                    );

                    /**
                     * Then we look at the response of the mutation.
                     */
                    const payloadConnection = ConnectionHandler.getConnection(
                        store.getRootField('favoritePage').getLinkedRecord('user'),
                        'updateSaveSearchMutation_savedSearches'
                    );
                    if (payloadConnection && favoritesConnection) {
                        const payloadEdges = payloadConnection.getLinkedRecords('edges');
                        if (payloadEdges?.length) {
                            /**
                             * If the response returned a new favorite objects, we know that the user
                             * intended to follow a new page and we will add it to the store.
                             */
                            const newEdge = ConnectionHandler.buildConnectionEdge(
                                store,
                                favoritesConnection,
                                payloadEdges[0]
                            );
                            if (newEdge) {
                                ConnectionHandler.insertEdgeBefore(favoritesConnection, newEdge);
                            }
                        } else if (favoriteRelayId) {
                            /**
                             * If the response did not return any new objects, we know that the intention
                             * was to remove an existing favorites, so we'll just delete it from the store.
                             */
                            ConnectionHandler.deleteNode(favoritesConnection, favoriteRelayId);
                        }
                    }
                }
            }
        },
    });
}
