import { useCallback, useEffect, useState, FC, ReactElement } from 'react';
import { graphql, useFragment } from 'react-relay';

// components
import { FormattedMessage } from 'dibs-react-intl';
import HeartOutlined from 'dibs-icons/exports/legacy/HeartOutlined';
import HeartFilled from 'dibs-icons/exports/legacy/HeartFilled';
import { Link } from 'dibs-elements/exports/Link';
import LikeHeartIcon from 'dibs-icons/exports/legacy/LikeHeartIcon';
import { Button } from '../../../global/Button/Button';

// helpers
import classnames from 'classnames';
import { FOLLOW_DEALER_FLOW, sharedLoginCheck } from '../../../authentication/sharedLoginCheck';
import { authLabels } from '../../../utils/tracking/shared/followSearchTracking';
import { pageTypeConstants as pageTypes } from '../../../constants/pageTypeConstants';
import { useSharedSaveSearchContext } from '../../../shared/SharedSaveSearchContext/SharedSaveSearchContext';

// styles
import dibsCss from 'dibs-css';
import styles from './SaveSearchButton.scss';

import { SaveSearchButton_itemSearch$key } from './__generated__/SaveSearchButton_itemSearch.graphql';

const parsePageType = (pageType: string | null): string => {
    if (pageType === pageTypes.MORE_FROM_SELLER || pageType === pageTypes.DEALER) {
        return pageTypes.DEALER;
    } else if (pageType === pageTypes.CREATOR) {
        return pageTypes.CREATOR;
    } else {
        return pageTypes.SEARCH;
    }
};

const getSaveSearchButtonContent = (
    isFollowing: boolean,
    pageType: string | null
): ReactElement => {
    const parsedPageType = parsePageType(pageType);
    if (isFollowing) {
        return (
            <div className={classnames(dibsCss.flex, dibsCss.justifyCenter, dibsCss.itemsCenter)}>
                <HeartFilled
                    className={classnames(dibsCss.h14px, dibsCss.w14px, dibsCss.mrXsmall)}
                />
                <FormattedMessage
                    id="shared.SaveSearchButton.savedSearch"
                    defaultMessage="{pageType, select,
                    seller {Saved Seller}
                    creator {Saved Creator}
                    other {Saved Search}
                }"
                    values={{ pageType: parsedPageType }}
                />
            </div>
        );
    } else {
        const className = classnames(dibsCss.h14px, dibsCss.w14px, dibsCss.mrXsmall, styles.button);
        return (
            <div className={classnames(dibsCss.flex, dibsCss.justifyCenter, dibsCss.itemsCenter)}>
                <HeartOutlined className={className} />
                <FormattedMessage
                    id="shared.SaveSearchButton.saveSearch"
                    defaultMessage="{pageType, select,
                    seller {Save Seller}
                    creator {Save Creator}
                    other {Save Search}
                }"
                    values={{ pageType: parsedPageType }}
                />
            </div>
        );
    }
};

const itemSearchFragment = graphql`
    fragment SaveSearchButton_itemSearch on ItemSearchQueryConnection {
        pageType
    }
`;

type Props = {
    itemSearch: SaveSearchButton_itemSearch$key | null | undefined;
    fullWidth?: boolean;
    locationLabel: (typeof authLabels)[keyof typeof authLabels] | null;
    onComplete?: (isFollowing: boolean) => void;
    size?: 'small' | 'medium' | 'large';
    type?: 'primary' | 'secondary' | 'heart';
};

export const SaveSearchButton: FC<Props> = ({
    itemSearch: itemSearchRef,
    fullWidth = false,
    locationLabel,
    onComplete = () => {},
    size = 'large',
    type,
}) => {
    const { initAutoFollow, isFollowing, favoriteId, favoritesFetched, updateSaveSearch, userId } =
        useSharedSaveSearchContext();
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);

    const [loading, setLoading] = useState(false);
    const [waitForConnection, setWaitForConnection] = useState(false);
    const [autoFollow, setAutoFollow] = useState(initAutoFollow);
    const pageType = itemSearch?.pageType || '';

    const showAuthModal = useCallback(() => {
        sharedLoginCheck({
            onSuccess: () => setWaitForConnection(true),
            onError: () => setLoading(false),
            authOptions: {
                flow: FOLLOW_DEALER_FLOW,
                ga: { label: locationLabel },
            },
        });
    }, [locationLabel]);

    const handleFollow = useCallback(
        async (_userId: string) => {
            await updateSaveSearch({ userId: _userId, onComplete });
            setLoading(false);
        },
        [onComplete, updateSaveSearch]
    );

    const handleClick = useCallback(() => {
        setLoading(true);
        if (userId) {
            handleFollow(userId);
        } else {
            showAuthModal();
        }
    }, [handleFollow, showAuthModal, userId]);

    useEffect(() => {
        /**
         * We must wait for the user fetch before sending off the mutation. We do this by checking
         * whether the favorites connection exists.
         *
         * There's a possibility that the mutation will resolve before the user fetch returns,
         * so the newly returned mutation result will be overridden with whatever comes from the
         * user query.
         */
        if (waitForConnection && userId && favoritesFetched) {
            setWaitForConnection(false);
            handleFollow(userId);
        }

        /**
         * If autoFollow is true, when the component renders it should try and follow the search automatically.
         * We need to consider both situations when the user is and is not logged in.
         *
         * Case 1:
         *
         * User is logged in, so userId is present in props.  If this is the case we need to make sure
         * favoritesConnection is populated (this information is asynced in).  Once we have this information
         * we check to make sure the user is already not following the search.
         * If they are not we trigger the click event on the button to handle the following logic.
         */
        if (autoFollow && favoritesFetched) {
            setAutoFollow(false);
            if (!favoriteId) {
                handleClick();
            }
        }

        /**
         * Case 2:
         *
         * In this case the user is not logged in so we trigger the handleClick function to follow the search.
         * At this point we need to know the user is not logged in, even though we make this check in the handleClick function,
         * because if the user is logged in we need to make sure favoritesConnection is populated (see Case 1)
         *
         */
        if (autoFollow && !userId) {
            setAutoFollow(false);
            handleClick();
        }
    }, [
        autoFollow,
        favoriteId,
        favoritesFetched,
        handleClick,
        handleFollow,
        userId,
        waitForConnection,
    ]);

    return type === 'heart' ? (
        <Link
            className={styles.heartLink}
            onClick={handleClick}
            dataTn="mobile-item-actions-follow-search"
        >
            <LikeHeartIcon
                className={classnames(
                    dibsCss.h18px,
                    dibsCss.w18px,
                    dibsCss.ptXxsmall,
                    styles.heartIcon
                )}
                isFilled={isFollowing || loading}
                animateHeartBeat={loading}
                theme="dark"
            />
        </Link>
    ) : (
        <Button
            className={classnames(styles.button, { [dibsCss.wFull]: fullWidth })}
            disabled={loading}
            size={size}
            isSecondary={size !== 'large' && type !== 'primary'}
            fullWidth={fullWidth}
            onClick={handleClick}
            dataTn="item-actions-follow-search"
        >
            {getSaveSearchButtonContent(isFollowing, pageType)}
        </Button>
    );
};
